import React, { useState, useEffect } from "react"

import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
    ToggleRefinement
  } from "react-instantsearch-dom"

  function IncludeLet(props) {
    return (
      <InstantSearch
          indexName={props.indexName}
          searchClient={props.searchClient}
          searchState={props.searchState}
          onSearchStateChange={props.onSearchStateChange}
          createURL={props.createURL}
          routing="true"
        >
          <div className="property-filter_check">
            <ToggleRefinement
                attribute="status"
                label="Include Let"
                value={['Let']}
                defaultRefinement={["Let"]}
            />
          </div>
          <div className="d-none">
            <ToggleRefinement
                attribute="status"
                label="Let Agreed"
                value={['Let Agreed']}
                defaultRefinement={['Let Agreed']}
            />
          </div>
      </InstantSearch>
    )
  }
  export default IncludeLet